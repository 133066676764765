import { EColors, EFontWeight, EFonts } from "src/config/enums";
import styled, { css } from "styled-components";

import { OxIcon } from "../OxIcon";
import * as ButtonStyled from "../OxButton/OxButton.styled";
import { TThemedInputContext } from "src/context/ThemedInputContext";
import { createTextStyle } from "src/utils/createTextStyle";
import { fluidSizing } from "src/utils";

export const Container = styled.div`
  position: relative;
  z-index: 5;
`;

export const Select = styled.select<{
  customTheme: TThemedInputContext;
  touched: boolean;
}>(
  ({ customTheme, touched }) => css`
    -webkit-appearance: none;
    width: 100%;
    border-width: 1px;
    border-style: solid;
    border-color: ${customTheme.borderColor};
    background-color: ${customTheme.backgroundColor};
    color: ${customTheme.color};
    transition: 200ms;
    ${createTextStyle(
      EFonts.Montserrat,
      EFontWeight.Medium,
      [10, 10, 10],
      [18, 18, 18]
    )}
    ${fluidSizing([
      { prop: "height", values: [30, 30, 30] },
      { prop: "border-radius", values: [15, 15, 15] },
      { prop: "padding-left", values: [15, 15, 15] },
      { prop: "padding-right", values: [15, 15, 15] }
    ])}
    &:focus {
      border-color: ${customTheme.hoverBorderColor};
      background-color: ${customTheme.hoverBackgroundColor};
      color: ${customTheme.hoverColor};
    }
    &:invalid {
      box-shadow: none;
    }
    ${touched &&
      css`
        &:invalid {
          border-color: ${EColors.DarkRed};
        }
      `}

    & * {
      height: 30px;
    }

    @media print {
      border: none;
      font-size: 1.6rem;
      height: 3rem;
      padding-left: 0;
      padding-right: 0;
    }
  `
);

export const Icon = styled(OxIcon)<{ customTheme: TThemedInputContext }>(
  ({ customTheme }) => css`
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: rotate(90deg) translateX(-50%);
    color: ${customTheme.color};
    ${fluidSizing([
      { prop: "height", values: [10, 10, 10] },
      { prop: "right", values: [15, 15, 15] }
    ])}

    @media print {
      display: none;
    }
  `
);

export const Spinner = styled(ButtonStyled.Spinner)`
  position: absolute;
  top: 25%;

  ${fluidSizing([{ prop: "right", values: [10, 10, 10] }])}
`;
