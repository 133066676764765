import { OxInput } from "../OxInput";
import { OxSelect } from "../OxSelect";
import { fluidSizing } from "src/utils";
import styled, { css } from "styled-components";
import MaterialPhoneInputCSS from "!!raw-loader!react-phone-input-2/lib/material.css";
import { TThemedInputContext } from "src/context/ThemedInputContext";
import { Error as InputError } from "src/components/OxInput/OxInput.styled";
import { createTextStyle } from "src/utils/createTextStyle";
import { EColors, EFonts, EFontWeight } from "src/config/enums";

export const Container = styled.div<{
  customTheme: TThemedInputContext;
}>(
  ({ theme, customTheme }) => css`
    display: flex;
    flex: 1;
    position: relative;

    ${MaterialPhoneInputCSS}

    .react-phone-input {
      display: flex;
      justify-content: space-between;

      .special-label {
        display: none;
      }
      &__input {
        border-color: ${customTheme.borderColor} !important;
        background-color: ${customTheme.backgroundColor} !important;
        box-shadow: none;
        color: ${customTheme.color};
        flex: 1;
        transition: 200ms;
        -webkit-appearance: none;
        ${createTextStyle(
          EFonts.Montserrat,
          EFontWeight.Medium,
          [10, 10, 10],
          [18, 18, 18],
          true
        )}
        ${fluidSizing([
          { prop: "height", values: [30, 30, 30], important: true },
          { prop: "border-radius", values: [15, 15, 15], important: true },
          {
            prop: "padding-left",
            values: [15, 15, 15],
            important: true,
            formatter: (val): string => `calc(${val}vw + 45px)`
          },
          { prop: "padding-bottom", values: [15, 15, 15], important: true },
          { prop: "padding-right", values: [15, 15, 15], important: true },
          { prop: "padding-top", values: [15, 15, 15], important: true }
        ])}
        &:focus {
          color: ${customTheme.hoverColor};
          border-color: ${customTheme.hoverBorderColor} !important;
          background-color: ${customTheme.hoverBackgroundColor} !important;
          box-shadow: none !important;
        }
        &.invalid-number {
          border-color: ${theme.colors.basic.darkRed} !important;

          &:focus {
            border-color: ${customTheme.hoverBorderColor} !important;
          }
        }
      }
      &__button {
        ${fluidSizing([
          { prop: "padding-left", values: [15, 15, 15], important: true }
        ])}
        z-index: 6 !important;

        .selected-flag {
          padding-left: 0;
        }
      }
      &__dropdown {
        background-color: ${EColors.SpringWood} !important;
        color: ${EColors.Gold};

        .country {
          &:hover {
            background-color: ${EColors.Cameo} !important;
          }
        }
      }
      &__search {
      }
    }
  `
);

export const CountryCodeSelector = styled(OxSelect)`
  ${fluidSizing([{ prop: "margin-right", values: [20, 20, 20] }])}
  width: 50%;
  & > select {
    ${fluidSizing([{ prop: "height", values: [30, 30, 30] }])}
  }
`;

export const PhoneNumberInput = styled(OxInput)`
  flex: 1;
`;

export const Error = styled(InputError)``;
