import * as Styled from "./OxCheckbox.styled";

import React, { InputHTMLAttributes, useContext } from "react";

import { ThemedInputContext } from "src/context/ThemedInputContext";

export type TOxCheckboxShape = "circle" | "roundedSquare" | "square";
export type TOxCheckboxCheckDesign = "default" | "alt";

export type TOxCheckboxProps = InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  labelLinkTitle?: string;
  labelLink?: string;
  labelLinkOnClick?: () => void;
  description?: string;
  shape?: TOxCheckboxShape;
  checkStyle?: TOxCheckboxCheckDesign;
  name: string;
  value: string;
  type?: string;
  errorMessage?: string;
  errorShow?: boolean;
  initialChecked?: boolean;
  onValueChange?: (val: string, e: MouseEvent) => void;
  descriptionClickAction?: () => void;
  child: JSX.Element | null;
  onInputClick?: () => void;
  fullLeftInput?: boolean;
};

export const OxCheckbox = ({
  className,
  description,
  descriptionClickAction,
  errorMessage,
  errorShow,
  id,
  checked,
  initialChecked,
  label,
  labelLink,
  labelLinkOnClick,
  labelLinkTitle,
  name,
  onClick,
  onValueChange,
  onInputClick,
  shape,
  checkStyle,
  type,
  child,
  disabled,
  fullLeftInput,
  ...props
}: SCProps<"input", TOxCheckboxProps>): JSX.Element => {
  const themeContext = useContext(ThemedInputContext);

  return (
    <Styled.Container
      className={className}
      onClick={onClick}
      shape={shape || "circle"}
      customTheme={themeContext}
      disabled={disabled}
    >
      <Styled.InputWrapper>
        <Styled.Input
          {...props}
          id={id}
          name={name}
          type={type || "checkbox"}
          checked={checked}
          customTheme={themeContext}
          checkStyle={checkStyle || "default"}
          onChange={(e) => onValueChange && onValueChange(e.target.value, e)}
          onClick={onInputClick}
          disabled={disabled}
          fullLeftInput={fullLeftInput}
        ></Styled.Input>
        <Styled.Label
          htmlFor={id}
          customTheme={themeContext}
          hasChild={!!child}
          disabled={disabled}
          shape={shape || "circle"}
          fullLeftInput={fullLeftInput}
        >
          {!child && label}
          {child}
        </Styled.Label>
        <Styled.Link to={labelLink} onClick={labelLinkOnClick}>
          {labelLinkTitle}
        </Styled.Link>
      </Styled.InputWrapper>
      {description && (
        <Styled.Description onClick={descriptionClickAction}>
          {description}
        </Styled.Description>
      )}
      {errorShow && <Styled.Error>{errorMessage}</Styled.Error>}
    </Styled.Container>
  );
};
