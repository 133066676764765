import * as Styled from "./OxRadioList.styled";

import React, { useEffect, useState } from "react";

import { OxRadio } from "../OxRadio";
import { TOxCheckboxCheckDesign, TOxCheckboxShape } from "../OxCheckbox";

export type TOxRadioListItem = {
  name: string;
  value: string;
  child?: JSX.Element | null;
};

type TProps = {
  name: string;
  label?: string;
  items: Array<TOxRadioListItem>;
  direction?: "row" | "column";
  radioShape?: TOxCheckboxShape;
  checkStyle?: TOxCheckboxCheckDesign;
  initialValue?: string;
  onValueChange?: (val: string) => void;
  required?: boolean;
  disabled?: boolean;
  fullLeftInput?: boolean;
};

export const OxRadioList = ({
  name,
  label,
  items,
  direction,
  radioShape,
  checkStyle,
  initialValue,
  onValueChange,
  required,
  disabled,
  fullLeftInput,
  ...props
}: TProps): JSX.Element => {
  const [checkedVal, setCheckedVal] = useState<string>(initialValue || "");

  useEffect((): void => {
    onValueChange && onValueChange(`${checkedVal}`);
  }, [checkedVal]);

  return (
    <>
      {label && <Styled.Label htmlFor={name}>{label}</Styled.Label>}
      <Styled.ItemContainer {...props} direction={direction}>
        {(items ?? []).map((item, index) => (
          <Styled.Item key={index}>
            <OxRadio
              key={index}
              name={name}
              id={`${name}${index}`}
              label={item.name}
              value={item.value}
              checked={checkedVal === item.value}
              shape={radioShape || "square"}
              checkStyle={checkStyle}
              required={required}
              child={item.child}
              fullLeftInput={fullLeftInput}
              onValueChange={(): void => setCheckedVal(`${item.value}`)}
              disabled={disabled}
            />
          </Styled.Item>
        ))}
      </Styled.ItemContainer>
    </>
  );
};
