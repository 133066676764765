import { EFontWeight, EFonts } from "src/config/enums";
import styled, { css } from "styled-components";

import { OxLink } from "src/components/OxLink";
import { TOxCheckboxCheckDesign, TOxCheckboxShape } from "./OxCheckbox";
import { TThemedInputContext } from "src/context/ThemedInputContext";
import { createTextStyle } from "src/utils/createTextStyle";
import { fluidSizing } from "src/utils";

const leftPadding = [26, 26, 26];

export const Container = styled.div<{
  customTheme: TThemedInputContext;
  shape?: TOxCheckboxShape;
  disabled?: boolean;
}>(
  ({ theme, shape, customTheme, disabled }) => css`
    display: flex;
    position: relative;
    user-select: none;
    flex-direction: column;
    ${disabled &&
      css`
        opacity: 0.5;
      `}
    ${fluidSizing([{ prop: "padding-left", values: leftPadding }])};
    label,
    a {
      color: ${customTheme.color};
    }
    ${(shape === "roundedSquare" || shape === "square") &&
      css`
        label,
        a {
          color: ${theme.colors.basic.black};
        }
      `}
  `
);

export const Label = styled.label<{
  customTheme: TThemedInputContext;
  shape?: TOxCheckboxShape;
  hasChild?: boolean;
  disabled?: boolean;
  fullLeftInput?: boolean;
}>(
  ({ theme, customTheme, shape, hasChild, disabled, fullLeftInput }) => css`
    display: flex;
    position: relative;
    color: ${customTheme.color};
    cursor: ${disabled ? "default" : "pointer"};
    ${createTextStyle(
      EFonts.Montserrat,
      EFontWeight.Normal,
      [9, 10, 10],
      [13, 18, 18]
    )}

    ${hasChild &&
      css`
        width: 100%;
      `}

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      border: 1px solid ${customTheme.color};
      ${fluidSizing([
        { prop: "height", values: [16, 16, 16] },
        { prop: "width", values: [16, 16, 16] },
        {
          prop: "left",
          values: fullLeftInput ? leftPadding.map(val => -val) : [-18, -18, -18]
        }
      ])};
      ${shape === "circle" &&
        css`
          border-radius: 100%;
        `}
      ${shape === "roundedSquare" &&
        css`
          border-radius: 6px;
          color: ${theme.colors.basic.black};
        `}
    }
    ${fluidSizing([
      { prop: "padding-left", values: [8, 5, 5] },
      { prop: "padding-right", values: [5, 5, 5] }
    ])}
  `
);

export const InputWrapper = styled.div`
  display: flex;
`;

export const Description = styled.p(
  ({ theme }) => css`
    color: ${theme.colors.primary.main};
    ${createTextStyle(
      EFonts.Montserrat,
      EFontWeight.Normal,
      [9, 10, 10],
      [13, 18, 18]
    )}
    ${fluidSizing([{ prop: "margin-left", values: [8, 5, 5] }])}
  `
);

export const Input = styled.input<{
  customTheme: TThemedInputContext;
  shape?: TOxCheckboxShape;
  checkStyle?: TOxCheckboxCheckDesign;
  fullLeftInput?: boolean;
}>(
  ({ theme, customTheme, checkStyle, fullLeftInput }) => css`
    opacity: 0;
    position: absolute;
    width: 1px;
    height: 1px;
    top: 50%;
    ${fluidSizing([{ prop: "left", values: [20, 20, 20] }])}
    &:checked + ${Label}:after {
      align-items: center;
      content: "";
      justify-content: center;
      display: flex;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background: ${theme.colors.basic.black};
      border-radius: 100%;
    ${fluidSizing([
      { prop: "height", values: [8, 8, 8] },
      { prop: "width", values: [8, 8, 8] },
      {
        prop: "left",
        values: fullLeftInput
          ? leftPadding.map(val => -val + 5)
          : [-13, -13, -13]
      }
    ])}

    ${checkStyle === "alt" &&
      css`
        background: ${customTheme.color};
        color: ${theme.colors.basic.white};
        content: "✓";

        ${fluidSizing([
          { prop: "height", values: [13, 13, 13] },
          { prop: "width", values: [13, 13, 13] },
          {
            prop: "left",
            values: fullLeftInput
              ? leftPadding.map(val => -val + 2)
              : [-16, -16, -16]
          }
        ])}
      `}
    }
  `
);

export const Link = styled(OxLink)`
  ${createTextStyle(
    EFonts.Montserrat,
    EFontWeight.Medium,
    [10, 10, 10],
    [13, 18, 18]
  )}
  text-decoration: underline;
`;

export const Error = styled.div`
  position: absolute;
  width: 100%;
  top: 82%;
  left: 8%;
  ${fluidSizing([{ prop: "line-height", values: [16, 16, 16] }])};
  ${createTextStyle(
    EFonts.Montserrat,
    EFontWeight.Medium,
    [8, 8, 8],
    [14, 14, 14]
  )}
`;
